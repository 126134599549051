import moment from 'moment';
import Swal from 'sweetalert2';
import 'ekko-lightbox';
import axios from 'axios';

const padTimeUnit = (timeUnit) => {
  if (timeUnit > 9) { return timeUnit }
  return `0${timeUnit}`
}

const updateCounter = (element, date) => {
  let diff = Math.round(date.diff(moment().utc()) / 1000)
  if (diff < 1) {
    diff = 0;
  }

  const hours = Math.floor(diff / 3600)
  const minutes = Math.floor(((diff % 3600) / 60))
  const seconds = diff % 60

  const h = padTimeUnit(hours)
  const m = padTimeUnit(minutes)
  const s = padTimeUnit(seconds)

  element.text(`${h}:${m}:${s}`)
}

export const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected = document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

const showNotification = notification => {
  const { type } = notification
  const icon = type === 'success' ? 'check' : 'alert'

  $.notify({
    // options
    icon: `mdi mdi-${icon}`,
    title: notification.title,
    message: notification.body
  }, {
    placement: {
      align: "right",
      from: "top"
    },
    showProgressbar: true,
    delay: notification.delay,
    timer: 1000,
    // settings
    type: notification.type,
    template: '<div data-notify="container" class=" bootstrap-notify " role="alert">' +
      '<div class="progress" data-notify="progressbar">' +
      '<div class="progress-bar bg-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
      '</div>' +
      '<div class="media"> <div class="avatar m-r-10 avatar-sm"> <div class="avatar-title bg-{0} rounded-circle"><span data-notify="icon"></span></div> </div>' +
      '<div class="media-body p-r-20"><div class="font-secondary" data-notify="title">{1}</div> ' +
      '<span class="opacity-75" data-notify="message">{2}</span></div>' +
      '<a href="{3}" target="{4}" data-notify="url"></a>' +
      ' <button type="button" aria-hidden="true" class="close" data-notify="dismiss"><span>x</span></button></div></div>'

  });
}

window.showNotification = showNotification;

$('[data-counter]').each(function () {
  const $el = $(this)
  const startDate = $el.attr('data-counter');
  const limit = $el.attr('data-counter-limit');
  const date = moment(startDate, 'YYYY-MM-DD HH:mm:ss').utc().add(limit, 'h');

  setInterval(() => {
    updateCounter($el, date);
  }, 1000)

  updateCounter($el, date)
});

// Flash messages
const notifications = window.notifications || []
notifications.forEach((notification) => {
  showNotification(notification);
});

// Notifications
$('.user-notifications').on('hidden.bs.dropdown', function (e) {
  if ($('.user-notifications .unread').length) {
    const url = $(this).data('clear');
    $.get(url);
  }

  $('.user-notifications .notification-counter').remove();
});

/**
 * @see https://sweetalert2.github.io/#configuration
 */
$(document).on("click", "[data-confirmation]", async function (e) {
  const button = $(this);
  if (button.data('confirmed')) {
    button.data('confirmed', false);
    return true;
  }

  e.preventDefault();

  const data = button.data('confirmation');
  const swalOptions = {
    title: 'Are you sure?',
    icon: 'warning',
    showCancelButton: true,
    ...data
  };

  const result = await Swal.fire(swalOptions);
  if (!result.value) {
    return false;
  }

  button.data('confirmed', true);
  if (button.attr('href')) {
    const value = typeof result.value === 'string' ? result.value : '';
    window.location.href = button.attr('href').replace(encodeURIComponent('{value}'), encodeURIComponent(value));
  } else {
    button.click();
  }
});

// Lightbox
$(document).on('click', '[data-toggle="lightbox"]', function(event) {
  event.preventDefault();
  $(this).ekkoLightbox();
});

$(document).ready(function () {
  $('[data-datepicker-config]').each(function () {
    const dataConfig = $(this).data('datepicker-config') || {};

    $(this).datepicker(dataConfig).on('changeDate', function (e) {
      if (dataConfig.target) {
        const d = moment(e.date);
        $(dataConfig.target).val(d.format('YYYY-MM-DD'));
      }
    });
  });

  $('[data-select]').each(function () {
    const config = {
      theme: 'bootstrap',
      ...($(this).data('select') || {})
    };

    $(this).select2(config)
  });

  $('[data-copy]').click(function (e) {
    e.preventDefault();
    const url = $(this).data('copy');
    copyToClipboard(url);

    showNotification({
      type: 'success',
      title: 'Link copied!',
      body: '',
      delay: 1000
    });
  });

  $('[data-popover]').each(function() {
    $(this).popover({
      ...($(this).data('popover') || {})
    });
  });

  $('[data-load-url]').each(async function () {
    this.addEventListener('ssr:reload', async () => {
      await loadContainer($(this));
    });
    
    await loadContainer($(this));
  });
});

export const loadContainer = async (container) => {
  $('.loader', container).show();
  $('.loaded-content', container).empty();

  try {
    const { data } = await axios.get(container.data('load-url'));
    $('.loader', container).hide();
    $('.loaded-content', container).html(data);
  } catch (err) {
    $(this).html(err.response.statusText);
  }
}


